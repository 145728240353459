import React from 'react'
import { AreaProps } from '..'
import { CarouselBanners } from './carousel-banners'
import CarouselSmallBanners from './carousel-small-banners'
import { useResponsive } from '@dustin-web/microsite-components/src/hooks'
import classnames from 'classnames'
import isPublicConvertedDate from '@dustin-web/content-components/src/utils'
import ErrorBoundary from '@dustin-web/microsite-components/src/utils/error-boundary'

type CarouselType = 'single' | 'multi'
type Props = AreaProps & {
  type: CarouselType
  title?: string
  disableSlider: boolean
  ssr?: boolean
}

export const CarouselBlock = (props: Props) => {
  return (
    <ErrorBoundary>
      <CarouselBlockComponent {...props}></CarouselBlockComponent>
    </ErrorBoundary>
  )
}

const CarouselBlockComponent = (props: Props) => {
  const { breakpoints } = useResponsive()
  const blocks = props.blocks ?? []

  const publishedBlocks = blocks.filter(block => {
    return isPublicConvertedDate(block.properties.startTime, block.properties.endTime)
  })
  return (
    <>
      {props.title && !props.disableSlider ? (
        <p
          className={classnames('u-pl-8 u-pl-lg-8 u-mb-16 col-12', {
            h2: breakpoints?.min.SM,
            h3: !breakpoints?.min.SM,
          })}>
          {props.title}
        </p>
      ) : null}
      {/* TODO REMOVE WHEN ABTEST IS DONE: abtest-startpage-bannerclicks */}
      {props.type === 'multi' ? (
        <CarouselSmallBanners
          name={props.name}
          blocks={publishedBlocks}
          disableSlider={props.disableSlider}
          ssr={props.ssr}
        />
      ) : (
        <CarouselBanners
          name={props.name}
          blocks={publishedBlocks}
          disableSlider={props.disableSlider}
          ssr={props.ssr}
        />
      )}
    </>
  )
}
