import { type GridBlock } from 'src/graphql/generated/graphql-types'
import { widthLayout } from './utils'

import dynamic from 'next/dynamic'

export const Blocks = {
  ArticleListBlock: dynamic(() => import('./article-list').then(mod => mod.ArticleListBlock)),
  CampaignProductListBlock: dynamic(() =>
    import('./campaign-product-list').then(mod => mod.CampaignProductListBlock)
  ),
  HtmlBlock: dynamic(() => import('./html').then(mod => mod.HtmlBlock)),
  MarkdownBlock: dynamic(() => import('./markdown').then(mod => mod.MarkdownBlock)),
  NavigationBoxesBlock: dynamic(() =>
    import('./navigation-boxes').then(mod => mod.NavigationBoxesBlock)
  ),
  TopSellingCampaignProductsBlock: dynamic(() =>
    import('./top-selling-campaign-products').then(mod => mod.TopSellingCampaignProductsBlock)
  ),
  SectionSingleColumnBlock: dynamic(() =>
    import('./section-single-column').then(mod => mod.SectionSingleColumnBlock)
  ),
  SectionTwoColumnBlock: dynamic(() =>
    import('./section-two-column').then(mod => mod.SectionTwoColumnBlock)
  ),
  SectionThreeColumnBlock: dynamic(() =>
    import('./section-three-column').then(mod => mod.SectionThreeColumnBlock)
  ),
  SectionFourColumnBlock: dynamic(() =>
    import('./section-four-column').then(mod => mod.SectionFourColumnBlock)
  ),
  ImageBlock: dynamic(() => import('./image/image').then(mod => mod.ImageBlock)),
  BoxBlock: dynamic(() => import('./box').then(mod => mod.BoxBlock)),
  LinkButtonBlock: dynamic(() => import('./link-button').then(mod => mod.LinkButtonBlock)),
  HeadingBlock: dynamic(() => import('./heading').then(mod => mod.HeadingBlock)),
  ParagraphBlock: dynamic(() => import('./paragraph').then(mod => mod.ParagraphBlock)),
  TabsBlock: dynamic(() => import('./tabs').then(mod => mod.TabsBlock)),
  RecentlyViewedBlock: dynamic(() =>
    import('./recently-viewed').then(mod => mod.RecentlyViewedBlock)
  ),
  CarouselBlock: dynamic(() => import('./carousel/carousel').then(mod => mod.CarouselBlock)),
  BannerflowBannerBlock: dynamic(() =>
    import('./bannerflowBanner').then(mod => mod.BannerflowBannerBlock)
  ),
  AboutBlock: dynamic(() => import('./about').then(mod => mod.AboutBlock)),
  TilesBlock: dynamic(() => import('./tiles').then(mod => mod.TilesBlock)),
  CardIconBlock: dynamic(() => import('./card-icon/card-icon').then(mod => mod.CardIconBlock)),
  ListBLock: dynamic(() => import('./list').then(mod => mod.ListBLock)),
  UspBlock: dynamic(() => import('./usp').then(mod => mod.UspBlock)),
  PageIntroductionBlock: dynamic(() =>
    import('./page-introduction-block').then(mod => mod.PageIntroductionBlock)
  ),
  AccordionsBlock: dynamic(() => import('./accordions/accordion').then(mod => mod.AccordionsBlock)),
  CardArticleHorizontalBlock: dynamic(() =>
    import('./cards/card-article-horizontal').then(mod => mod.CardArticleHorizontalBlock)
  ),
  CardArticleVerticalBlock: dynamic(() =>
    import('./cards/card-article-vertical').then(mod => mod.CardArticleVerticalBlock)
  ),
  CardCTAHorizontalBlock: dynamic(() =>
    import('./cards/card-cta-horizontal').then(mod => mod.CardCTAHorizontalBlock)
  ),
  CardCTAVerticalBlock: dynamic(() =>
    import('./cards/card-cta-vertical').then(mod => mod.CardCTAVerticalBlock)
  ),
  CardsArticleListBlock: dynamic(() =>
    import('./cards/cards-article-list').then(mod => mod.CardsArticleListBlock)
  ),
  ProductsSliderBlock: dynamic(() =>
    import('./products/products-slider').then(mod => mod.ProductsSliderBlock)
  ),
  ProductsListBlock: dynamic(() =>
    import('./products/products-list').then(mod => mod.ProductsListBlock)
  ),
  JSCodeBlock: dynamic(() => import('./js-code').then(mod => mod.JSCodeBlock)),
}

export { Block, BlockList } from './block'

export {
  generateBackgroundStyles,
  generateDarkenLightenStyles,
  generateColorThemeClasses,
  generatePaddingClasses,
  generateForegroundStyles,
  generateColumnClasses,
  generateTopRadiusClasses,
} from './utils'

export type AreaProps = {
  name: string
  blocks: GridBlock[]
}

export type SectionSettingsProps = GridBlock & {
  backgroundColor?: { color: string }
  backgroundImage?: { url: string }
  darkenLighten?: number
  colorTheme?: string
  paddingTop?: string
  paddingBottom?: string
  paddingY?: string
  foregroundColor?: { color: string }
  widthLayout?: widthLayout
  roundedTop?: boolean
}
