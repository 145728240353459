import React, { useMemo } from 'react'
import { Block } from '..'
import { CarouselTest } from '@dustin-web/microsite-components/src/carousel-test/carousel'
import { IframeSkeleton } from '@dustin-web/microsite-components/src/skeleton'
import shuffle from 'lodash.shuffle'
import { useResponsive, useSiteContext } from '@dustin-web/microsite-components/src/hooks'
import { GridBlock } from 'src/graphql/generated/graphql-types'
import classNames from 'classnames'

type Props = {
  name?: string
  blocks: GridBlock[]
  disableSlider?: boolean
  ssr?: boolean
}

export const CarouselBanners = ({ blocks, disableSlider = false, ssr }: Props) => {
  const shuffledBlocks = useMemo(() => shuffle(blocks), [])
  const { breakpoints } = useResponsive()

  const children = shuffledBlocks.map((block, index) => (
    <Block block={block} key={index} bordered />
  ))
  const { isMobile } = useSiteContext()

  return (
    <div
      className={classNames(
        'col-12 col-xl-9 u-pl-lg-8 u-py-0 u-mb-xl-0 startpage__slider startpage__slider--banner',
        {
          'u-mb-24': children?.length === 1,
          'u-mb-56':
            (children?.length > 1 && breakpoints.max.LG) ||
            (children?.length > 1 && breakpoints.min.LG),
        }
      )}>
      <IframeSkeleton type={'wide'}>
        {shuffledBlocks?.length > 1 && !disableSlider ? (
          <CarouselTest
            type="startpageBanners"
            infinite={true}
            dots={children?.length > 1}
            list={children}
            autoPlay={!isMobile}
            autoPlaySpeed={5000}
            dotMode={'right'}
            itemClass={'u-w-100'}
            ssr={ssr}
          />
        ) : blocks[0]?.properties ? (
          <div>
            <Block block={blocks[0]} />
          </div>
        ) : null}
      </IframeSkeleton>
    </div>
  )
}
