export const generateBackgroundStyles = ({
  backgroundColor,
  backgroundImage,
}: {
  backgroundColor?: { color: string }
  backgroundImage?: { url: string }
}) => {
  var backgroundColorStyle = backgroundColor?.color
    ? { backgroundColor: '#' + backgroundColor?.color }
    : {}
  var backgroundImageStyle = backgroundImage?.url
    ? {
        background: `url(${backgroundImage?.url})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }
    : {}

  var style = {
    ...backgroundColorStyle,
    ...backgroundImageStyle,
  }

  return style
}

export type sectionType = 'single' | 'double' | 'triple'

export type widthLayout =
  | '100%'
  | '75%'
  | '50%'
  | '25%'
  | '50 / 50 %'
  | '75 / 25 %'
  | '25 / 75 %'
  | '33 / 33 / 33 %'
  | '50 / 25 / 25 %'
  | '25 / 50 / 25 %'
  | '25 / 25 / 50 %'
export interface ColClasses {
  firstCol: string
  secondCol: string
  thirdCol: string
}

export const generateDarkenLightenStyles = ({ darkenLighten }: { darkenLighten?: number }) => {
  if (!darkenLighten) {
    return {}
  }

  if (darkenLighten < 0) {
    return { background: `rgb(0,0,0,${Math.abs(darkenLighten / 100)})` }
  }

  if (darkenLighten > 0) {
    return { background: `rgb(255,255,255,${darkenLighten / 100})` }
  }

  return {}
}

export const generateColorThemeClasses = ({ colorTheme }: { colorTheme?: string }) => {
  return colorTheme ? `t-${colorTheme.toLowerCase()}` : ''
}

export const generateColumnClasses = ({
  widthLayout,
  sectionType,
}: {
  widthLayout?: widthLayout
  sectionType: sectionType
}) => {
  const colClasses: ColClasses = {
    firstCol: '',
    secondCol: '',
    thirdCol: '',
  }

  const deffaultColClasses: ColClasses = {
    firstCol: '',
    secondCol: '',
    thirdCol: '',
  }

  switch (sectionType) {
    case 'double':
      deffaultColClasses.firstCol = 'col-md-6'
      deffaultColClasses.secondCol = 'col-md-6'
      break
    case 'triple':
      deffaultColClasses.firstCol = 'col-md-4'
      deffaultColClasses.secondCol = 'col-md-4'
      deffaultColClasses.thirdCol = 'col-md-4'
      break
  }

  switch (widthLayout) {
    case '75%':
      colClasses.firstCol = 'col-md-9'
      break
    case '50%':
      colClasses.firstCol = 'col-md-6'
      break
    case '25%':
      colClasses.firstCol = 'col-md-3'
      break
    case '50 / 50 %':
      colClasses.firstCol = 'col-md-6'
      colClasses.secondCol = 'col-md-6'
      break
    case '75 / 25 %':
      colClasses.firstCol = 'col-md-9'
      colClasses.secondCol = 'col-md-3'
      break
    case '25 / 75 %':
      colClasses.firstCol = 'col-md-3'
      colClasses.secondCol = 'col-md-9'
      break
    case '33 / 33 / 33 %':
      colClasses.firstCol = 'col-md-4'
      colClasses.secondCol = 'col-md-4'
      colClasses.thirdCol = 'col-md-4'
      break
    case '50 / 25 / 25 %':
      colClasses.firstCol = 'col-md-6'
      colClasses.secondCol = 'col-md-3'
      colClasses.thirdCol = 'col-md-3'
      break
    case '25 / 50 / 25 %':
      colClasses.firstCol = 'col-md-3'
      colClasses.secondCol = 'col-md-6'
      colClasses.thirdCol = 'col-md-3'
      break
    case '25 / 25 / 50 %':
      colClasses.firstCol = 'col-md-3'
      colClasses.secondCol = 'col-md-3'
      colClasses.thirdCol = 'col-md-6'
      break
    default:
      colClasses.firstCol = deffaultColClasses.firstCol
      colClasses.secondCol = deffaultColClasses.secondCol
      colClasses.thirdCol = deffaultColClasses.thirdCol
  }
  return colClasses
}

export const generateTopRadiusClasses = ({ roundedTop }: { roundedTop?: boolean }) =>
  roundedTop ? 'u-radius-8 u-radius-bottom-0' : ''

export const generatePaddingClasses = ({
  paddingTop,
  paddingBottom,
  paddingY,
}: {
  paddingTop?: string
  paddingBottom?: string
  paddingY?: string
}) => {
  let pt: string
  let pb: string
  let py: string

  // paddingY - deprecated property, will be removed when all blocks have been checked
  if (paddingY) {
    switch (paddingY) {
      case 'xl (6.4 rem)':
        py = 'u-py-64'
        break
      case 'lg (4.0 rem)':
        py = 'u-py-40'
        break
      case 'md (2.4 rem)':
        py = 'u-py-24'
        break
      case 'sm (1.6 rem)':
        py = 'u-py-16'
        break
      default:
        py = ''
    }
    return py
  }

  switch (paddingTop) {
    case 'xl (6.4 rem)':
      pt = 'u-pt-64'
      break
    case 'lg (4.0 rem)':
      pt = 'u-pt-40'
      break
    case 'md (2.4 rem)':
      pt = 'u-pt-24'
      break
    case 'sm (1.6 rem)':
      pt = 'u-pt-16'
      break
    case 'none':
      pt = ''
      break
    default:
      pt = 'u-pt-24'
  }

  switch (paddingBottom) {
    case 'xl (6.4 rem)':
      pb = 'u-pb-64'
      break
    case 'lg (4.0 rem)':
      pb = 'u-pb-40'
      break
    case 'md (2.4 rem)':
      pb = 'u-pb-24'
      break
    case 'sm (1.6 rem)':
      pb = 'u-pb-16'
      break
    case 'none':
      pb = ''
      break
    default:
      pb = 'u-pb-24'
  }

  return `${pt} ${pb}`
}

export const generateForegroundStyles = ({
  foregroundColor,
}: {
  foregroundColor?: { color: string }
}) => {
  return foregroundColor ? { color: '#' + foregroundColor.color } : {}
}
