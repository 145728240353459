import {
  useResponsive,
  useTranslations,
  useWebToggles,
} from '@dustin-web/microsite-components/src/hooks'
import { getMainCategoryIcon } from '@dustin-web/microsite-components/src/utils/main-category-icons'
import { GET_CATEGORY_NAVIGATION_SHALLOW } from '@dustin-web/microsite-data/src/graphql/product/category-navigation.graphql'
import { GetCategoryNavigationShallowQuery } from '@dustin-web/microsite-data/src/graphql/product/generated/graphql-types'
import { useGraphql } from '@dustin-web/microsite-data/src/hooks'
import { CarouselBlock } from 'src/components/blocks/carousel/carousel'
import { GridBlock, GetUpperLevelCategoriesQuery } from 'src/graphql/generated/graphql-types'
import { GetUpperLevelCategories } from 'src/graphql/upper-level-categories.graphql'
import classNames from 'classnames'
import { DigitalData } from '@dustin-web/microsite-components/src/utils/digital-data'
import {
  NavigationEventType,
  NavigationSelectionType,
} from '@dustin-web/microsite-components/src/utils/digital-data-types'

export type HeroProps = { blocks: GridBlock[] }
export const Hero = ({ blocks }: HeroProps) => {
  const { translate } = useTranslations()
  const { breakpoints } = useResponsive()
  const { isToggleEnabled } = useWebToggles()

  const { data } = useGraphql<GetUpperLevelCategoriesQuery>(GetUpperLevelCategories, {
    variables: { limit: 8 },
  })
  const { data: shallowCategriesData } = useGraphql<GetCategoryNavigationShallowQuery>(
    GET_CATEGORY_NAVIGATION_SHALLOW
  )

  let firstPageSelectedCategories
  if (isToggleEnabled('categories-by-relevance-on-startpage')) {
    firstPageSelectedCategories = data?.upperLevelCategories || []
  } else {
    const firstPageCategoryIDs = [
      '2037749',
      '2037751',
      '2037866',
      '2037759',
      '2029810',
      '2029808',
      '2029786',
      '2029788',
    ]

    const mainCategories = shallowCategriesData?.categoryNavigation?.children || []
    firstPageSelectedCategories = firstPageCategoryIDs
      .map((id: string, index: number) => {
        const category = mainCategories.filter(category => category.categoryId === id)
        if (breakpoints.max.SM && index < 6) {
          return category
        }
        return category
      })
      .flat()
      .map(category => ({
        categoryId: category.categoryId,
        name: category.categoryDisplayName,
        url: category.url,
      }))
  }

  return (
    <div className="u-bg-white">
      <div className="container">
        <div className="row d-block d-lg-flex flex-row-reverse u-pt-24 u-pt-md-32 u-pb-24 u-pb-lg-40">
          <CarouselBlock
            name={'StartpageCarousel'}
            blocks={blocks}
            type="single"
            disableSlider={isToggleEnabled('abtest-startpage-bannerclicks')}
            ssr={false}
          />
          <div className="col-12 col-xl-3 u-pr-xl-24">
            <div className="c-category-and-banner-area--grid u-mb-12 u-mb-lg-24">
              {firstPageSelectedCategories.map((category, index) => {
                return (
                  category?.url && (
                    <div className="d-flex c-category-and-banner-area--grid__item" key={index}>
                      <a
                        className="d-flex align-items-start flex-column align-items-xl-center u-w-100 u-h-md-40 u-bg-white d-block u-px-8 u-py-8 u-px-sm-12 u-py-sm-12 u-radius-2 u-decoration-none"
                        style={{ border: '1px solid black' }}
                        onClick={() =>
                          DigitalData.pushNavigationInteraction(
                            NavigationEventType.CategoryMenu,
                            category.url!,
                            'link_click',
                            {
                              selectionType: NavigationSelectionType.CategoryEntrance,
                              disableSelectionSanitize: true,
                            }
                          )
                        }
                        href={category.url}
                        key={category.categoryId}>
                        {getMainCategoryIcon(
                          category.categoryId,
                          'i-20 flex-shrink-0 u-mr-md-12 u-mb-8 u-mb-xl-0'
                        )}{' '}
                        <span
                          className={classNames('u-fs-12 u-fs-md-14', {
                            'u-line-height-1': breakpoints.min.XL,
                          })}
                          style={{ paddingTop: breakpoints.min.XL ? '2px' : 'initial' }}>
                          {category.name}
                        </span>
                      </a>
                    </div>
                  )
                )
              })}
            </div>
            <a
              className="d-block u-text-left u-text-md-right u-text-xl-left"
              onClick={() =>
                DigitalData.pushNavigationInteraction(
                  NavigationEventType.CategoryMenu,
                  'show_all_categories',
                  'link_click',
                  {
                    selectionType: NavigationSelectionType.CategoryEntrance,
                    disableSelectionSanitize: true,
                  }
                )
              }
              href={translate('AllCategories_URL')}>
              {translate('Show_all_categories')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
