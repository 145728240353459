import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Block } from '..'
import classnames from 'classnames'
import { CarouselTest } from '@dustin-web/microsite-components/src/carousel-test/carousel'
import { useResponsive } from '@dustin-web/microsite-components/src/hooks'
import SliderSettings from '@dustin-web/microsite-components/src/carousel-test/settings'
import { GridBlock } from 'src/graphql/generated/graphql-types'
import { Skeleton } from '@dustin-web/microsite-components/src/skeleton'
import { WindowBreakpoints } from '@dustin-web/microsite-config'

type Props = {
  name?: string
  blocks: GridBlock[]
  disableSlider?: boolean
  ssr?: boolean
}

const CarouselSmallBanners = ({ blocks, disableSlider = false, ssr }: Props) => {
  const [data, setData] = useState<GridBlock[]>([])
  const { breakpoints } = useResponsive()
  const settings = SliderSettings()

  useEffect(() => {
    if (blocks) {
      setData(blocks)
    }
  }, [blocks])

  const getItemsToScroll = (
    settings: Record<string, any>,
    breakpoints: WindowBreakpoints,
    type: string
  ): number => {
    if (breakpoints.min.XL) {
      return settings[`${type}`].xl.items
    } else if (breakpoints.min.LG) {
      return settings[`${type}`].lg.items
    } else if (breakpoints.min.MD) {
      return settings[`${type}`].md.items
    } else if (breakpoints.min.SM) {
      return settings[`${type}`].sm.items
    } else if (breakpoints.max.SM) {
      return settings[`${type}`].mobile.items
    } else {
      return 1
    }
  }

  const isSliderEnabled =
    getItemsToScroll(settings, breakpoints, 'startpage') < data?.length && !disableSlider

  const blocksList = data?.map((blocksItem, index) => (
    <div key={index}>
      <Block block={blocksItem} />
    </div>
  ))

  const items =
    (isSliderEnabled && (
      <CarouselTest
        type={'startpage'}
        partialVisible={true}
        infinite={false}
        list={blocksList}
        dots={blocksList.length > 0}
        dotMode="full-width"
        dotClass="custom-dot--startpage-ads"
        ssr={ssr}
      />
    )) ||
    blocksList

  return (
    <div className="slider-content startpage__slider startpage__slider--ads container">
      <div
        className={classnames('startpage__slider--ads--wrapper u-px-8', {
          'grid-layout': data?.length > 0 && !isSliderEnabled,
          'u-mb-12 u-mb-lg-0': isSliderEnabled,
          'abtest-bannerclicks': disableSlider,
        })}>
        {(data?.length > 0 && items) || (
          <Skeleton className="slider-skeleton u-h-100" shade="dark" noAnimation={false} />
        )}
      </div>
    </div>
  )
}

export default CarouselSmallBanners
