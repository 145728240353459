import { ContentBlockFragment, GridBlock } from 'src/graphql/generated/graphql-types'
import { Blocks } from '.'

type Props = {
  block: GridBlock
  availableColumns?: number
  bordered?: boolean
}

export const Block = (props: Props) => {
  const { block, availableColumns, bordered } = props
  const { properties, areas, columnSpan, type } = block

  type AvailableBlockNames = keyof typeof Blocks
  const TheBlock = Blocks[type as AvailableBlockNames]

  const ratio = columnSpan / (availableColumns ?? 12)
  const actualColumns = 12 * ratio

  return TheBlock ? (
    isNotWrapped(block) || bordered ? (
      <TheBlock
        {...properties}
        areas={areas}
        columnSpan={columnSpan}
        availableColumns={availableColumns}
        bordered={bordered}
      />
    ) : (
      <div data-block-type={block.type} className={'col-12 col-md-' + Math.min(actualColumns, 12)}>
        <TheBlock
          {...properties}
          areas={areas}
          columnSpan={columnSpan}
          availableColumns={availableColumns}
        />
      </div>
    )
  ) : (
    <div>UNKNOWN BLOCK: {block.type}</div>
  )
}

type BlockListProps = {
  blocks: ContentBlockFragment[]
  firstLevel?: boolean
  availableColumns?: number
}

export const BlockList = (props: BlockListProps) => {
  const { blocks, availableColumns } = props

  const blockLists = blocks.reduce((acc, current) => {
    if (isNotWrapped(current)) {
      const newAcc = [...acc, [current]]
      return newAcc
    } else {
      const lastArray1 = acc.length > 0 ? acc[acc.length - 1] : []
      const lastBlock = lastArray1?.pop()

      if (lastBlock && isNotWrapped(lastBlock)) {
        const newAcc = [...acc, [current]]
        return newAcc
      }

      var lastArray = acc.pop() ?? []
      const newLastArray = [...lastArray, current]

      const newAcc = [...acc, newLastArray]
      return newAcc
    }
  }, [] as ContentBlockFragment[][])

  return (
    <>
      {blockLists.map((bl, i) =>
        bl[0] && isNotWrapped(bl[0]) ? (
          bl.map((block, index) => (
            <Block block={block as GridBlock} key={index} availableColumns={availableColumns} />
          ))
        ) : (
          <div key={i} className="container">
            <div className="row">
              {bl.map((block, index) => (
                <Block block={block as GridBlock} key={index} availableColumns={availableColumns} />
              ))}
            </div>
          </div>
        )
      )}
    </>
  )
}

const isNotWrapped = (block: ContentBlockFragment) => {
  return (
    block.type === 'AccordionsBlock' ||
    block.type === 'ArticleListBlock' ||
    block.type === 'CarouselBlock' ||
    block.type === 'ParagraphBlock' ||
    block.type === 'BannerflowBannerBlock' ||
    block.type === 'BoxBlock' ||
    block.type === 'SectionSingleColumnBlock' ||
    block.type === 'SectionTwoColumnBlock' ||
    block.type === 'SectionThreeColumnBlock' ||
    block.type === 'SectionFourColumnBlock' ||
    block.type === 'CampaignProductListBlock' ||
    block.type === 'TopSellingCampaignProductsBlock' ||
    block.type === 'TabsBlock' ||
    block.type === 'RecentlyViewedBlock' ||
    block.type === 'AboutBlock' ||
    block.type === 'TilesBlock' ||
    block.type === 'CardIconBlock' ||
    block.type === 'ImageBlock' ||
    block.type === 'PageIntroductionBlock' ||
    block.type === 'HeadingBlock' ||
    block.type === 'LinkButtonBlock' ||
    block.type === 'ListBLock' ||
    block.type === 'MarkdownBlock' ||
    block.type === 'HtmlBlock' ||
    block.type === 'CardArticleHorizontalBlock' ||
    block.type === 'CardArticleVerticalBlock' ||
    block.type === 'CardCTAHorizontalBlock' ||
    block.type === 'CardCTAVerticalBlock' ||
    block.type === 'CardsArticleListBlock' ||
    block.type === 'ProductsSliderBlock' ||
    block.type === 'ProductsListBlock' ||
    block.type === 'JSCodeBlock'
  )
}
